import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { intersection } from 'lodash';
import difference from 'lodash/difference';
import { PlansQuery } from '../../services';
import { getIds, removePlansWithIds } from '../../utils/plan';
import { DefaultSettingsAdapter } from '../PackagePicker/DefaultSettingsAdapter';

export class PlanListSettingsAdapter extends DefaultSettingsAdapter {
  getHiddenPlanIds(plans: PublicPlan[]): string[] {
    const visiblePlans = this.asArray('visiblePlans');
    const planIds = getIds(plans);
    if (visiblePlans.length > 0 || this.get('useVisiblePlans')) {
      return difference(planIds, visiblePlans);
    } else {
      return intersection(planIds, this.asArray('hiddenPlans'));
    }
  }

  hidePlan(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.filter((visibleId) => visibleId !== id).join(','));
  }

  unhidePlan(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.concat(id).join(','));
  }

  runMigrations(plans: PublicPlan[]) {
    if (!this.get('useVisiblePlans') && this.asArray('visiblePlans').length === 0) {
      const visiblePlans = removePlansWithIds(plans, this.asArray('hiddenPlans'));
      this.set('visiblePlans', getIds(visiblePlans).join(','));
      this.set('hiddenPlans', '');
    }
    this.set('useVisiblePlans', true);
  }

  shouldFetchVisiblePlans(loadedPlans: PublicPlan[]) {
    const loadedIds = getIds(loadedPlans);
    return this.asArray('visiblePlans').some((id) => !loadedIds.includes(id));
  }

  getVisiblePlansQuery(): PlansQuery {
    return { include: this.asArray('visiblePlans') };
  }
}
